// src/pages/ProjectPage.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import projectsData from '../data/projectsData';
import '../styles/Project.css';

function ProjectPage() {
    const { projectId } = useParams();
    const [content, setContent] = useState('');
    const [project, setProject] = useState(null);

    useEffect(() => {
        fetch(`/projects/${projectId}.html`)
            .then(response => {
                if (!response.ok) throw new Error('Network response was not ok.');
                return response.text();
            })
            .then(text => setContent(text))
            .catch(error => console.error('Error loading project HTML:', error));

        const foundProject = projectsData.find(p => p.id === projectId);
        setProject(foundProject);
    }, [projectId]);

    return (
        <div className="project-page-container"> { }
            {project ? (
                <>
                    <div dangerouslySetInnerHTML={{ __html: content }} /> { }
                </>
            ) : (
                <h1>Project Not Found</h1>
            )}
        </div>
    );
}

export default ProjectPage;
