import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/ProjectGrid.css';

import ProjectPreview from './ProjectPreview.js';

const ProjectGrid = ({ projects }) => {
    return (
        <div className="project-grid">
            {projects.map(project => (
                <div key={project.id} className="project-grid-item">
                    <Link to={`/projects/${project.id}`}>
                        <ProjectPreview
                            image={project.image}
                            title={project.title}
                            description={project.description}
                        />
                    </Link>
                </div>
            ))}
        </div>
    );
};

export default ProjectGrid;
