import React from 'react';
import ProjectPreview from '../components/ProjectPreview';
import ProjectGrid from '../components/ProjectGrid';
import projects from '../data/projectsData';
import { Link } from 'react-router-dom';
import AllProject from './AllProjects'

import '../styles/Home.css'

function Home() {
    return (
        <div className="home-container">
            <h1>Nicola</h1>
            <p>Hello! I’m a software developer passionate about technologies and computational mathematics.
                Explore my portfolio to see a curated selection of projects. </p>
            <p>I am still updating the project pages; they will all be explained in details and interactive once done. </p>
            <div className="action-button">
                <Link to="/all-projects">
                    <button><span>All Projects</span></button>
                </Link>
            </div>
            <div className="project-section">
                <h2>Projects</h2>
                <ProjectGrid projects={projects} /> { }
            </div>
        </div>
    );
}

export default Home;
