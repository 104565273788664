const projects = [
    {
        id: 'SpriteGan',
        image: '/assets/spritesGan/SpriteGeneration.png',
        title: 'Sprite GAN',
        description: 'A generative adverserial network approach to recreate sprites.',
        category: 'Software'
    },
    {
        id: 'DatingApp',
        image: '/assets/LoginDatingApp.png',
        title: 'Dating App',
        description: 'A cozy dating websites where users match their friends instead of themselves.',
        category: 'Software'
    },
    {
        id: 'Sokoban',
        image: '/assets/Games/Sokoban.png',
        title: 'Sokoban Penguin',
        description: 'Puzzle Game',
        category: 'Gamedev'
    },
    {
        id: 'Games',
        image: '/assets/games/AbyssCity.png',
        title: 'Other game previews',
        description: 'Other game previews.',
        category: 'Gamedev'
    },
    {
        id: 'Nbody',
        image: '/assets/ten_thousands_periodic.gif',
        title: 'NBody solver',
        description: 'A solver to solve a 10 million + body problem',
        category: 'Software'
    },
    {
        id: 'Dmd',
        image: '/assets/dptrajectories.png',
        title: 'Dynamic Mode Decomposition',
        description: 'A review and analysis of the dmd algorithm',
        category: 'Software'
    },

];

export default projects;
