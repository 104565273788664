import React from 'react';
import ProjectGrid from '../components/ProjectGrid';
import projectsData from '../data/projectsData';

function AllProjects() {
    return (
        <div className="default-container">
            <h1>All Projects</h1>
            <ProjectGrid projects={projectsData} /> {/* Use the ProjectGrid component */}
        </div>
    );
}

export default AllProjects;
